/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import {
  Form, Formik, FormikErrors, FormikHelpers
} from 'formik';
import {
  DatePicker, NumberField, TextField
} from '@instech/components';
import { FloppyDisk, Search } from '@instech/icons';
import styled from 'styled-components';
import {
  ButtonGroup, Button, Pane
} from '../../wrappedComponents';
import { InsuranceObject, InsuranceObjectRequest } from '../../../services/objectsService';
import { FormWrapper } from '../../layout/FormWrapper';
import { getDateOnlyString, getDateISOString } from '../../../utils/date';
import { UpperCaseTextField } from '../../form/UpperCaseTextField';
import { canSubmit, createOperations } from './formUtils';
import { RenameFormValues } from './types';
import { effectiveDateIsAfterInsurancePeriods, validateDateInput } from '../../form/utils';
import { useOperationsModal } from '../../modal/useOperationsModal';
import { Message } from '../../messages/Message';
import { getSelectedVesselObjects, SelectedVessel } from '../../../utils/vessel';

const initialValues: RenameFormValues = {
  imoNumber: '',
  oldName: '',
  newName: '',
  effectiveDate: getDateISOString(new Date()),
  emailRecipients: ''
};

interface InstructionsProps {
    hasRequestTimedOut: boolean;
}

const Instructions = ({ hasRequestTimedOut }: InstructionsProps) => (
  <article>
    <p>Fill out the Effective date and IMO number or Vessel name to find the vessels to rename.</p>
    <p>Click the Find button to search and select the vessels to rename.</p>
    <p>Fill out the New vessel name and Email Recipients fields and click the Rename button to rename the vessels.</p>
    {hasRequestTimedOut && <p style={{ color: 'red' }}>Try search again with more specific vessel name or IMO number to get results</p>}
  </article>
);

const PaneContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 230px;
`;

interface Props {
  setVessel: (vessel?: InsuranceObjectRequest) => void;
  onComplete: () => void;
  vessels: InsuranceObject[];
  selectedVessels: SelectedVessel[];
  hasRequestTimedOut: boolean;
}
export const RenameVesselForm = ({ setVessel, onComplete, vessels, selectedVessels, hasRequestTimedOut }: Props) => {
  const { startOperations, close } = useOperationsModal();
  const [startError, setStartError] = useState<string>();

  const handleFindVessel = (values: RenameFormValues, errors: FormikErrors<RenameFormValues>) => {
    if (errors.imoNumber || errors.oldName || errors.effectiveDate) return;
    setVessel({ imoNumber: values.imoNumber, name: values.oldName, effectiveDate: getDateOnlyString(values.effectiveDate) });
  };

  const validateForm = (values: RenameFormValues) => {
    const errors: FormikErrors<RenameFormValues> = {};

    const [effectiveDateIsValid, effectiveDateError] = validateDateInput(values.effectiveDate, true);
    if (!effectiveDateIsValid) {
      errors.effectiveDate = effectiveDateError;
    }

    return errors;
  };

  const handleRenameVessels = async (values: RenameFormValues, helpers: FormikHelpers<RenameFormValues>) => {
    setStartError(undefined);
    try {
      const effectiveDate = new Date(values.effectiveDate);
      if (effectiveDateIsAfterInsurancePeriods(vessels, selectedVessels, effectiveDate)) {
        helpers.setFieldError('effectiveDate', 'Effective date must be before "Insurance period to"');
        return;
      }

      const selectedVesselObjects = getSelectedVesselObjects(vessels, selectedVessels.filter(v => v.isClicked));
      const changeOperations = createOperations(selectedVesselObjects, values);
      const { allStartedOk, startResponses } = await startOperations('Rename vessel', changeOperations);

      if (allStartedOk) {
        helpers.resetForm();
        onComplete();
      } else if (startResponses.some(response => response?.status === 400)) {
        // Assumes bad request is due to email recipients not passing server validation
        helpers.setFieldError('emailRecipients', 'Validation failed!');
        if (startResponses.every(response => response?.status === 400)) close();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setStartError('Something went wrong when trying to start name change');
    }
  };

  return (
    <Pane title="Find and rename vessels">
      <PaneContent>
        <FormWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={handleRenameVessels}
            validate={validateForm}
          >
            {({ handleSubmit, values, errors }) => (
              <Form>
                <FormFields>
                  <NumberField label="IMO number" name="imoNumber" placeholder="1234" />
                  <TextField label="Vessel name" name="oldName" placeholder="MS Expect More" />
                  <DatePicker label="Effective date" name="effectiveDate" placeholder={`ex: "1 Jan ${new Date().getFullYear()}"`} required />
                  <UpperCaseTextField label="New vessel name" name="newName" placeholder="MV Expect More" required forceMaxLength maxLength={40} />
                  <TextField label="Email recipients (comma separated)" name="emailRecipients" placeholder="first.last@domain.com" />
                </FormFields>
                <ButtonGroup>
                  <Button onClick={() => handleFindVessel(values, errors)} disabled={!values.imoNumber && !values.oldName} endIcon={<Search />}>Find</Button>
                  <Button onClick={handleSubmit} variant="secondary" disabled={!canSubmit(values, selectedVessels)} endIcon={<FloppyDisk />}>Rename</Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </FormWrapper>
        <Instructions hasRequestTimedOut={hasRequestTimedOut} />
      </PaneContent>
      {startError && <Message type="error" message={startError} />}
    </Pane>
  );
};
