import {
  useCallback, useEffect, useState
} from 'react';
import axios, { isAxiosError } from 'axios';
import { addQueryParams } from './client';

const config = {
  headers: {
    'X-CSRF': '1'
  }
};

export interface Claim {
  type: string;
  value: string;
}
export interface UserClaims {
  isAuthenticated: boolean;
  claims?: Claim[]
}

export const useClaims = () => {
  const [data, setData] = useState<UserClaims>();
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState(true);

  const fetchClaims = useCallback(async (params?: { slide?: boolean }) : Promise<Claim[]> => {
    try {
      const result = await axios.get<Claim[]>(addQueryParams('/management/user', params), config);
      setData({ isAuthenticated: true, claims: result.data });
      return result?.data ?? [];
    } catch (err) {
      if (isAxiosError(err) && err.response?.status === 401) {
        setData({ isAuthenticated: false });
      } else {
        setError(err);
      }
    } finally {
      setLoading(false);
    }
    return [];
  }, []);

  const slideUserSession = useCallback(() => fetchClaims({ slide: true }), [fetchClaims]);

  useEffect(() => {
    void fetchClaims();
  }, [fetchClaims]);

  return {
    data,
    error,
    initializing: loading && !data,
    slideUserSession
  };
};
