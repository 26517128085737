import React, { useState } from 'react';
import {
  Form, Formik, FormikErrors, FormikHelpers
} from 'formik';
import {
  DatePicker, Dropdown, LabelValuePair, NumberField, TextField
} from '@instech/components';
import { FloppyDisk, Search } from '@instech/icons';
import styled from 'styled-components';
import {
  ButtonGroup, Button, Pane
} from '../../wrappedComponents';
import { InsuranceObject, InsuranceObjectRequest } from '../../../services/objectsService';
import { FormWrapper } from '../../layout/FormWrapper';
import { getDateOnlyString, getDateISOString } from '../../../utils/date';
import { effectiveDateIsAfterInsurancePeriods, validateDateInput } from '../../form/utils';
import { FormValues } from './types';
import { canSubmit, createOperations } from './formUtils';
import { useOperationsModal } from '../../modal/useOperationsModal';
import { Message } from '../../messages/Message';
import { getSelectedVesselObjects, SelectedVessel } from '../../../utils/vessel';

const initialValues: FormValues = {
  imoNumber: '',
  name: '',
  effectiveDate: getDateISOString(new Date()),
  flag: '',
  emailRecipients: ''
};

interface InstructionsProps {
    hasRequestTimedOut: boolean;
}

const Instructions = ({ hasRequestTimedOut }: InstructionsProps) => (
  <article>
    <p>Fill out the Effective date and IMO number or Vessel name to find the vessels that needs a flag change.</p>
    <p>Click the Find button to search and select the vessels.</p>
    <p>Select the New flag and Email Recipients fields and click the Change flag button to change the flag on the selected vessel(s).</p>
    {hasRequestTimedOut && <p style={{ color: 'red' }}>Try search again with more specific vessel name or IMO number to get results</p>}
  </article>
);

const PaneContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 230px;
`;

interface Props {
  setVessel: (vessel?: InsuranceObjectRequest) => void;
  onComplete: () => void;
  vessels: InsuranceObject[];
  selectedVessels: SelectedVessel[];
  flags: LabelValuePair[];
  hasRequestTimedOut: boolean;
}
export const ChangeFlagForm = ({ setVessel, onComplete, vessels, selectedVessels, flags, hasRequestTimedOut }: Props) => {
  const { startOperations, close } = useOperationsModal();
  const [startError, setStartError] = useState<string>();

  const handleFindVessel = (values: FormValues, errors: FormikErrors<FormValues>) => {
    if (errors.imoNumber || errors.name || errors.effectiveDate) return;
    setVessel({ imoNumber: values.imoNumber, name: values.name, effectiveDate: getDateOnlyString(values.effectiveDate) });
  };

  const validateForm = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    const [effectiveDateIsValid, effectiveDateError] = validateDateInput(values.effectiveDate, true);
    if (!effectiveDateIsValid) {
      errors.effectiveDate = effectiveDateError;
    }

    return errors;
  };

  const handleChangeVesselFlags = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    setStartError(undefined);
    try {
      const effectiveDate = new Date(values.effectiveDate);
      if (effectiveDateIsAfterInsurancePeriods(vessels, selectedVessels, effectiveDate)) {
        helpers.setFieldError('effectiveDate', 'Effective date must be before "Insurance period to"');
        return;
      }

      const selectedVesselObjects = getSelectedVesselObjects(vessels, selectedVessels.filter(v => v.isClicked));
      const changeOperations = createOperations(selectedVesselObjects, values);
      const { allStartedOk, startResponses } = await startOperations('Change vessel flag', changeOperations);

      if (allStartedOk) {
        helpers.resetForm();
        onComplete();
      } else if (startResponses.some(response => response?.status === 400)) {
        // Assumes bad request is due to email recipients not passing server validation
        helpers.setFieldError('emailRecipients', 'Validation failed!');
        if (startResponses.every(response => response?.status === 400)) close();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setStartError('Something went wrong when trying to start flag change');
    }
  };

  return (
    <Pane title="Find vessels and change flags">
      <PaneContent>
        <FormWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={handleChangeVesselFlags}
            validate={validateForm}
          >
            {({ handleSubmit, values, errors }) => (
              <Form>
                <FormFields>
                  <NumberField label="IMO number" name="imoNumber" placeholder="1234" />
                  <TextField label="Vessel name" name="name" placeholder="MS Expect More" />
                  <DatePicker label="Effective date" name="effectiveDate" placeholder={`ex: "1 Jan ${new Date().getFullYear()}"`} required />
                  <Dropdown label="New flag" name="flag" searchable required options={flags} />
                  <TextField label="Email recipients (comma separated)" name="emailRecipients" placeholder="first.last@domain.com" />
                </FormFields>
                <ButtonGroup>
                  <Button onClick={() => handleFindVessel(values, errors)} disabled={!values.imoNumber && !values.name} endIcon={<Search />}>
                    Find
                  </Button>
                  <Button onClick={handleSubmit} variant="secondary" disabled={!canSubmit(values, selectedVessels)} endIcon={<FloppyDisk />}>
                    Change flag
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        </FormWrapper>
        <Instructions hasRequestTimedOut={hasRequestTimedOut} />
      </PaneContent>
      {startError && <Message type="error" message={startError} />}
    </Pane>
  );
};
